
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'IsStateTargetingOffersSelector',
    props: {
        modelValue: {
            default: [],
            required: true
        },
    },
    computed: {
        mdl: {
            get(){
                return this.modelValue;
            },
            set(v){
                this.$emit('update:modelValue', v);
            }
        }
    },
    data(){
        return {
            options: [],
            showArchive: false
        };
    },
    async mounted() {
        this.options = [
            { id: 'true', name: "True" },
            { id: 'false', name: "False" },
            { id:'all', name: 'All' },
        ];
    }
});
