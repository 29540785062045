
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {defineComponent, onMounted} from "vue";
import {setCurrentPageButtons} from "@/core/helpers/page-buttons";
import DataService from "@/core/services/DataService";
import ActiveOffersSelector from '@/components/selectors/ActiveOffersSelector.vue';
import IsVtaOffersSelector from '@/components/selectors/IsVtaOffersSelector.vue';
import IsStateTargetingOffersSelector from '@/components/selectors/IsStateTargetingOffersSelector.vue';

export default defineComponent({
	name: 'offer-list',
	components: { ActiveOffersSelector, IsVtaOffersSelector, IsStateTargetingOffersSelector },
	setup() {
		onMounted(() => {
			setCurrentPageBreadcrumbs('Offers', []);
			setCurrentPageButtons(null, []);
		});
	},
	data() {
		return {
			busy: false,
			records: [],
			filters: {
				keyword: '',
				effective_status: 'all',
				is_vta: 'all',
				is_state_targeting: 'all',
			},
			pagination: {
				page: 1,
				page_size: 20,
				total: 0
			},
		};
	},
	methods: {
		getListPayload(format = "json") {
			return {
				keyword: this.filters.keyword,
				page: this.pagination.page,
				limit: this.pagination.page_size,
				out_format: format
			}
		},
		async loadData(){
			if (this.busy){
				return;
			}
			try {
				let data = {
					keyword: this.filters.keyword,
					effective_status: this.filters.effective_status,
					is_vta: this.filters.is_vta,
					is_state_targeting: this.filters.is_state_targeting,
					page: this.pagination.page,
					limit: this.pagination.page_size,
				};
				console.log("data: ", data);
				this.busy = true;
				let resp = await DataService.get('offers/getList', data);
				this.records = resp.records;
				this.pagination.total = resp.total;
			} catch(e: any) {
				// alert("something went wrong");
			} finally {
				this.busy = false;
				// this.checkedRecords = [];
			}
		}
	},
	created() {
		this.loadData();
	},
});
